import React, { useState } from "react";
import Footer from "../components/Footer";
//import Cube from "../components/Brewery/OBJ_Example";
import ThreeSceneGLB from "../components/Brewery/GLB-PointerlockControls";
import WhyYBSection from "../components/Info";
const Brewery = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <WhyYBSection />
      <ThreeSceneGLB />
      {/* <GLB /> */}
      <Footer />
    </div>
  );
};

export default Brewery;
