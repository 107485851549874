import styled from "styled-components";
import {
  brownNameTag,
  darkColor,
  lightColor,
  primaryColor,
  primaryHoverColor,
  secondaryColor,
} from "../../colors";
import WhyYBSection from "../Info";

export const Einfo = styled.div`
  background: ${({ active }) => (active ? lightColor : secondaryColor)};
  display: flex;
  width: 230px;
  flex-direction: column;
  justify-content: flex-start;
  position: relative
  marginBottom: -200px;
  left: 50%;

  transform: translate(-50%, -50%);

  opacity: 0.6;
  align-items: column;
  border-radius: 10px;
  max-height: 340px;
  padding: 20px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${lightColor};
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
export const Einfop = styled.p`
  font-size: 2rem;
  text-align: center;
  line-height: 95%;
  color: ${darkColor};
  padding: 5px 5px;
`;
export const Overlay = styled.div`
  background: ${({ active }) => (active ? lightColor : secondaryColor)};
  display: flex;
  width: 230px;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: ${WhyYBSection.offsetHeight};
  right: 5px;

  transform: translate(0%, -0%);
  color: black;
  fontsize: 2rem;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 20px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${lightColor};
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
