import React, { Component } from "react";
import styled from "styled-components";
import * as THREE from "three";
import OrbitControls from "three-orbitcontrols";
import { GLTFLoader } from "./GLTFLoader";
// import Object01 from "./source/Brewb.glb";
import Object01 from "./source/caveolin.glb";
// NOT WORKING BECAUSE OF IMAGE LOAD PROBLEMS import Object01 from "./medieval_brewery/scene.glb";
import { PointerLockControls } from "./PointerLockControls.js";
import { Einfo, Einfop, Overlay } from "./OverlayStyles";

let camera, scene, renderer, controls;

const objects = [];

let raycaster;

let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let canJump = false;
let prevTime = performance.now();
const velocity = new THREE.Vector3();
const direction = new THREE.Vector3();
const vertex = new THREE.Vector3();
const color = new THREE.Color();
var interactible = false;
var interactp;
var infoDivBool = false;
var infoDivObejct;
var width;
var height;
var pickHelper;
var pickedObject;
class PickHelper {
  constructor() {
    this.raycaster = new THREE.Raycaster();
    pickedObject = null;
    this.pickedObjectSavedColor = 0;
  }
  pick(normalizedPosition, scene, camera, time) {
    // restore the color if there is a picked object
    if (pickedObject) {
      pickedObject.material.emissive.setHex(this.pickedObjectSavedColor);
      pickedObject = undefined;
    }

    // cast a ray through the frustum
    this.raycaster.setFromCamera(normalizedPosition, camera);
    // get the list of objects the ray intersected
    const intersectedObjects = this.raycaster.intersectObjects(scene.children);
    if (intersectedObjects.length) {
      // pick the first object. It's the closest one
      pickedObject = intersectedObjects[0].object;

      // save its color
      this.pickedObjectSavedColor = pickedObject.material.emissive.getHex();
      // set its emissive color to flashing red/yellow
      if (pickedObject.name == "Caveolin-1_27pics3001") {
        pickedObject.material.emissive.setHex(0x441111);
        interactible = true;
      } else {
        //  pickedObject.material.emissive.setHex(0x050505);
        interactible = false;
      }
    }
  }
}

class ThreeSceneGLB extends Component {
  componentDidMount() {
    // prevent that space bar hitting moves to bottom of page.
    window.addEventListener("keydown", function (e) {
      if (e.key === " " && e.target == document.body) {
        e.preventDefault();
      }
    });
    width = this.mount.clientWidth;
    height = this.mount.clientHeight;

    camera = new THREE.PerspectiveCamera(75, width / height, 1, 1000);

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    scene.fog = new THREE.Fog(0xffffff, 0, 20);

    const light = new THREE.HemisphereLight(0xeeeeff, 0x777788, 0.75);
    light.position.set(0.5, 1, 0.75);
    scene.add(light);
    //

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    this.mount.appendChild(renderer.domElement);
    // document.body.appendChild(renderer.domElement);
    this.pickPosition = { x: 0, y: 0 };
    pickHelper = new PickHelper();

    const onKeyDown = function (event) {
      switch (event.code) {
        case "ArrowUp":
        case "KeyW":
          moveForward = true;
          break;

        case "ArrowLeft":
        case "KeyA":
          moveLeft = true;
          break;

        case "ArrowDown":
        case "KeyS":
          moveBackward = true;
          break;

        case "ArrowRight":
        case "KeyD":
          moveRight = true;
          break;

        case "Space":
          if (canJump === true) velocity.y += 350;
          canJump = false;
          break;
        case "KeyE":
          console.log(pickHelper);
          if (pickedObject) {
            console.log("picked any object");
            if (pickedObject.name == "Caveolin-1_27pics3001") {
              console.log("picked right object");
              if (!infoDivBool) {
                infoDivBool = true;
              } else {
                infoDivBool = false;
              }
            } else {
              infoDivBool = false;
            }
          }
          break;
      }
    };

    const onKeyUp = function (event) {
      switch (event.code) {
        case "ArrowUp":
        case "KeyW":
          moveForward = false;
          break;

        case "ArrowLeft":
        case "KeyA":
          moveLeft = false;
          break;

        case "ArrowDown":
        case "KeyS":
          moveBackward = false;
          break;

        case "ArrowRight":
        case "KeyD":
          moveRight = false;
          break;
      }
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    raycaster = new THREE.Raycaster(
      new THREE.Vector3(),
      new THREE.Vector3(0, -1, 0),
      0,
      10
    );
    //

    window.addEventListener("resize", onWindowResize);

    function onWindowResize() {
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);
    }
    ////////////////// #################
    {
      const planeSize = 40;

      const loader = new THREE.TextureLoader();
      const texture = loader.load(
        "https://threejsfundamentals.org/threejs/resources/images/checker.png"
      );
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.magFilter = THREE.NearestFilter;
      const repeats = planeSize / 2;
      texture.repeat.set(repeats, repeats);

      const planeGeo = new THREE.PlaneGeometry(planeSize, planeSize);
      const planeMat = new THREE.MeshPhongMaterial({
        map: texture,
        side: THREE.DoubleSide,
      });
      const mesh = new THREE.Mesh(planeGeo, planeMat);
      mesh.rotation.x = Math.PI * -0.5;
      scene.add(mesh);
    }
    function frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
      const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      // compute a unit vector that points in the direction the camera is now
      // in the xz plane from the center of the box
      const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .multiply(new THREE.Vector3(1, 0, 1))
        .normalize();

      // move the camera to a position distance units way from the center
      // in whatever direction the camera was from the center already

      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

      // pick some near and far values for the frustum that
      // will contain the box.
      camera.near = boxSize / 100;
      camera.far = boxSize * 100;

      camera.updateProjectionMatrix();

      // point the camera to look at the center of the box
      camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
      camera.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
    }
    {
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(Object01, (gltf) => {
        const root = gltf.scene;
        scene.add(root);

        // compute the box that contains all the stuff
        // from root and below
        const box = new THREE.Box3().setFromObject(root);

        const boxSize = box.getSize(new THREE.Vector3()).length();
        const boxCenter = box.getCenter(new THREE.Vector3());

        // set the camera to frame the box
        frameArea(boxSize * 0.5, boxSize, boxCenter, camera);

        // update the Controls.
        controls = new PointerLockControls(camera, this.mount);
        const blocker = document.getElementById("blocker");
        const instructions = document.getElementById("instructions");

        instructions.addEventListener("click", function () {
          controls.lock();
        });

        controls.addEventListener("lock", function () {
          instructions.style.display = "none";
          blocker.style.display = "none";
        });

        controls.addEventListener("unlock", function () {
          blocker.style.display = "block";
          instructions.style.display = "";
        });
        this.start();
        scene.getObjectByName("Caveolin-1_27pics3001").material =
          new THREE.MeshLambertMaterial({
            color: 0xff0000,
            transparent: true,
            opacity: 0.5,
          });
        scene.getObjectByName("Caveolin-1_27pics3001").material.side =
          THREE.DoubleSide;
      });
    }

    function getCanvasRelativePosition(event) {
      const rect = this.mount.getBoundingClientRect();
      return {
        x: ((event.clientX - rect.left) * width) / rect.width,
        y: ((event.clientY - rect.top) * height) / rect.height,
      };
    }

    function setPickPosition(event) {
      const pos = getCanvasRelativePosition(event);
      this.pickPosition.x = (pos.x / width) * 2 - 1;
      this.pickPosition.y = (pos.y / height) * -2 + 1; // note we flip Y
    }

    function clearPickPosition() {
      this.pickPosition.x = -100000;
      this.pickPosition.y = -100000;
    }

    //start animation
  }

  ///////////////

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };
  animate = () => {
    //Animate Models Here
    //ReDraw Scene with Camera and Scene Object

    pickHelper.pick(this.pickPosition, scene, camera, this.time);
    window.addEventListener("mousemove", this.setPickPosition);
    window.addEventListener("mouseout", this.clearPickPosition);
    window.addEventListener("mouseleave", this.clearPickPosition);

    this.frameId = window.requestAnimationFrame(this.animate);

    const time = performance.now();

    if (controls.isLocked === true) {
      raycaster.ray.origin.copy(controls.getObject().position);
      raycaster.ray.origin.y -= 10;

      const intersections = raycaster.intersectObjects(objects, false);

      const onObject = intersections.length > 0;

      const delta = (time - prevTime) / 1000;

      velocity.x -= velocity.x * 10.0 * delta;
      velocity.z -= velocity.z * 10.0 * delta;

      velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

      direction.z = Number(moveForward) - Number(moveBackward);
      direction.x = Number(moveRight) - Number(moveLeft);
      direction.normalize(); // this ensures consistent movements in all directions

      if (moveForward || moveBackward)
        velocity.z -= direction.z * 400.0 * delta;
      if (moveLeft || moveRight) velocity.x -= direction.x * 400.0 * delta;

      if (onObject === true) {
        velocity.y = Math.max(0, velocity.y);
        canJump = true;
      }
      if (interactible) {
        interactp = document.getElementById("interact");
        interactp.style.display = "block";
      } else {
        interactp = document.getElementById("interact");
        interactp.style.display = "none";
      }
      if (infoDivBool) {
        infoDivObejct = document.getElementById("info");
        infoDivObejct.style.display = "block";
      } else {
        infoDivObejct = document.getElementById("info");
        infoDivObejct.style.display = "none";
      }

      controls.moveRight(-velocity.x * delta * 0.05);
      controls.moveForward(-velocity.z * delta * 0.05);

      controls.getObject().position.y += velocity.y * delta * 0.05; // new behavior

      if (controls.getObject().position.y < 1) {
        velocity.y = 0;
        controls.getObject().position.y = 1;

        canJump = true;
      }
    }

    prevTime = time;
    this.renderScene();
  };

  renderScene = () => {
    //  this.movePlayer();
    if (camera.position.z < -20) {
      camera.position.z = -20;
    } else if (camera.position.x < -20) {
      camera.position.x = -20;
    } else if (camera.position.z > 20) {
      camera.position.z = 20;
    } else if (camera.position.x > 20) {
      camera.position.x = 20;
    }

    if (renderer) renderer.render(scene, camera);
  };
  render() {
    return (
      <div
        style={{ width: "100%", height: "600px" }}
        ref={(mount) => {
          this.mount = mount;
        }}
      >
        <Einfo id="info" style={{ display: "none" }}>
          <Einfop>Interact: E</Einfop>
        </Einfo>
        <Overlay id="interact" style={{ display: "none" }}>
          <p>
            This is Caveolin-1, a protein found in caveolae at the plasma
            membrane, but also in endocytosed vesicles and compartments as the
            early endosome.
          </p>
        </Overlay>

        <div
          id="blocker"
          src={{
            position: "absolute",
            width: "100%",
            height: "600px",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div
            id="instructions"
            style={{
              width: "100%",
              height: "600px",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              textAlign: "center",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                fontSize: "14px",
              }}
            >
              Click to play
            </p>
            <p>
              Move: WASD
              <br />
              Jump: SPACE
              <br />
              Look: MOUSE
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default ThreeSceneGLB;
