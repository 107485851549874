import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { darkColor, lightColor, primaryColor } from "../../colors";

export const WhyYBContainer = styled.div`
  height: 200 px;
  display: flex;
  background: #15130e;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 40px 0;
  overflow-x: hidden;
  backgroundsize: "1500px 750px";
`;
export const BackgroundBoxWrap = styled.div`
  background: #15130e;
  padding: 0px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: 80px;
  margin-right: 80px;
  @media screen and (max-width: 1200px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
export const WhyYBH2 = styled.h2`
  font-size: 1.5rem;
  color: ${lightColor};
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  @media screen and (max-width: 1400px) {
    font-size: 1.3rem;
    margin-top: -10px;
  }
  @media screen and (max-width: 780px) {
    font-size: 1.15rem;
    margin-top: -20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    margin-top: -50px;
  }
  @media screen and (max-width: 400px) {
    font-size: 0.9rem;
  }
`;
export const WhyYBH1Container = styled.div`
  width: 102%;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  position: relative;
  margin-left: -2%;

  z-index: 200;
  grid-template-columns: 1fr;
  padding: 0px 0px;
`;
export const WhyYBH1Image = styled.img`
  max-height: 70px;
  min-height: 70px;
  background: #15130e;
  display: flex;
  object-fit: none;
  -o-object-fit: none;
  overflow-x: hidden;
  z-index: 200;
  @media screen and (max-width: 2700px) {
    max-height: 100px;
    min-height: 100px;
  }
  @media screen and (max-width: 1700px) {
    max-height: 80px;
    min-height: 80px;
  }
  @media screen and (max-width: 1400px) {
    max-height: 60px;
    min-height: 60px;
  }
  @media screen and (max-width: 1000px) {
    max-height: 50px;
    min-height: 50px;
  }
  @media screen and (max-width: 520px) {
    max-height: 40px;
    min-height: 40px;
  }
`;
export const List = styled.p`
  padding: 0px 0px 0px 60px;
  @media screen and (max-width: 520px) {
    padding: 0px 0px 0px 40px;
  }
`;

export const WhyYBH1 = styled.h1`
  text-align: center;
  font-size: 3.8vw;
  letter-spacing: 2px;
  position: absolute;
  margin-top: 10px;
  z-index: 200;
  padding: 0px 10px;

  font-family: BigFont;
  color: ${primaryColor};

  @media screen and (max-width: 720px) {
    font-size: 3.8vw;
  }
  @media screen and (max-width: 450px) {
    font-size: 5.4vw;
    letter-spacing: 1.2px;
  }
`;
