import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLOGOWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  Img,
  BroImg,
  FooterNameTag,
  QuoteH2,
} from "./FooterElements";
import {
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  BroImageWrap,
  SocialIconLink,
} from "./FooterElements";
const Footer = () => {
  return (
    <>
      <FooterContainer id="links">
        {/* The container & Wrapper stretch over everything */}
        <FooterWrap>
          <FooterLinkTitle>
            Contact me in case you want to know more about this project!
          </FooterLinkTitle>
          <FooterLinksContainer>
            <FooterLOGOWrapper>
              <FooterLinkItems>
                <SocialIconLink
                  aria-label="Twitter"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://twitter.com/Habacef";
                  }}
                >
                  <Img
                    src={require("../../images/web/Twitter.webp")?.default}
                    alt="Twitter Icon"
                  />
                </SocialIconLink>
              </FooterLinkItems>
            </FooterLOGOWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <WebsiteRights>
                Leons Webgame © {new Date().getFullYear()} All rights reserverd.
                Impressum: Leon Obendorf, Fraunhoferstraße 25, 10597 Berlin.
                E-mail: leonobendorf@yahoo.com
              </WebsiteRights>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
