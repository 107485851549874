import React from "react";
import { lightColor } from "../../colors";
import {
  BackgroundBoxWrap,
  List,
  TileImage,
  WhyITWrap,
  WhyYBContainer,
  WhyYBH1,
  WhyYBH1Container,
  WhyYBH1Image,
  WhyYBH2,
  YBTileWrap,
} from "./WhyYBElements";
const WhyYBSection = () => {
  return (
    <WhyYBContainer
      style={{
        backgroundImage: `url(${
          require("../../images/web/BGoptimized.webp")?.default
        })`,
      }}
    >
      <BackgroundBoxWrap>
        <WhyYBH1Container>
          <WhyYBH1Image
            src={require("../../images/web/Rectangle_32.webp")?.default}
          />
          <WhyYBH1>Welcome to "Explore immunofluorescence"</WhyYBH1>
        </WhyYBH1Container>
        <WhyYBH2>
          <List
            style={{
              color: lightColor,
              fontSize: "1.2rem",
              fontWeight: "lighter",
            }}
          >
            <ul>
              <li style={{ marginBottom: "7px" }}>
                {
                  '\n This website was made as a small "add on" to my application for a PhD position.\n'
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  "\n Within my biochemistry studies, I created a 3D model of cells originating from a z-stack of confocal immunofluorescence images of HUVECs exposed for 24 hours to low laminar fluid shear stress (FSS) of 1 dyne. The model was generated using ImageJ and Blender. It is a reconstruction of a surface around areas of high fluorescence intensity. This means it shows artificially generated surfaces, which are not present within the cell, it is not meant to analyze anything in a scientific way, but just for visualization purposes."
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  "\n To play, click on the view below & walk around the cells using W, S, A and D, look around using the trackpad or mouse and jump using space. Can you find some large Caveolin-1 structures (possibly early endosomes) containing Endoglin? \n"
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  <p>
                    {"\n "}
                    In blue, a z-stack slice of the nucleus is shown and was
                    generated by <span style={{ color: "#006ff2" }}>DAPI </span>
                    staining of double helical DNA.{"\n"}
                  </p>
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  <p>
                    {"\n"} In red,{" "}
                    <span style={{ color: "#ff3322" }}>Caveolin-1</span> was
                    stained. It is a protein which is the main component of
                    caveolae and involved in endocytosis but also integrin
                    signaling. {"\n"}
                  </p>
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  <p>
                    {"\n"} In green,{" "}
                    <span style={{ color: "#22dd22" }}>Endoglin</span> was
                    stained. Endoglin is a BMP co-receptor important in
                    modulating BMP-signaling, especially in the endothelium
                    during angiogenesis. {"\n"}
                  </p>
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  "\n Previously it was observed that differential FSS alters BMP-signaling, therefore we had a look at BMP receptors within this study.\n"
                }
              </li>
              <li style={{ marginBottom: "7px" }}>
                {
                  "\n This project can be adapted to z-stacks of various fluorescence images.\n"
                }
              </li>
            </ul>
          </List>
        </WhyYBH2>
      </BackgroundBoxWrap>
    </WhyYBContainer>
  );
};

export default WhyYBSection;
